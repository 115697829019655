<template>
  <v-container>
    <v-card>
      <v-card-title primary-title> Profile </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
